import * as _ from 'lodash'
import {AnyAction} from 'redux'
import {UPDATE_COMPONENT_SETTINGS_DRAFT, UPDATE_STYLE_PARAMS} from '../actions/component'
import {Component, ComponentSettings, ImageRatio} from '../types'

const defaultState: Component = {
  id: null,
  settings: {} as ComponentSettings,
}

export const component = (state = defaultState, action: AnyAction): Component => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    case UPDATE_COMPONENT_SETTINGS_DRAFT.REQUEST:
      return {
        ...state,
        settings: ensureEnumeratedCompSettings(mergeCompSettings(action.args[0], state.settings)),
      }
    default:
      return state
  }
}

export const mergeCompSettings = (
  newSettings: Partial<ComponentSettings>,
  oldSettings: Partial<ComponentSettings>,
): ComponentSettings => {
  const keys = [
    'aboutTitleText',
    'readLessText',
    'readMoreText',
    'rsvpButtonText',
    'shareTitleText',
    'ticketsTitleText',
    'timeAndLocationTitleText',
    'scheduleTitleText',
  ]
  const oldTexts = _.pick(oldSettings, keys)
  const newTexts = _.pick(newSettings, keys)
  oldSettings = _.omit(oldSettings, keys)
  newSettings = _.omit(newSettings, keys)

  const mergedSettings = {
    ...oldSettings,
    ...newSettings,
    texts: {
      ...oldTexts,
      ...oldSettings.texts,
      ...newTexts,
      ...newSettings.texts,
    },
  } as ComponentSettings

  return mergedSettings
}

const ensureEnumeratedCompSettings = (settings: ComponentSettings): ComponentSettings => ({
  ...settings,
  imageRatio: typeof settings.imageRatio === 'string' ? ImageRatio[settings.imageRatio as string] : settings.imageRatio,
})
